import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { PixiComponent, useApp } from "@inlet/react-pixi";
import { Viewport as PixiViewport } from 'pixi-viewport';
import { useMapContext } from './MapContext';


const PixiComponentViewport = PixiComponent("Viewport", {
    create: (props) => {
        const viewport = new PixiViewport({
            screenWidth: props.screenWidth,
            screenHeight: props.screenHeight,
            worldWidth: props.worldWidth,
            worldHeight: props.worldHeight,

            ticker: props.app.ticker,
            interaction: props.app.renderer.plugins.interaction,
        });

        viewport.drag().pinch().wheel();

        const onDragStart = () => {
            props.setUserIsDragging(true);
        };
        const onDragEnd = () => {
            props.setUserIsDragging(false);
        };

        // Attach viewport events
        viewport.on('drag-start', onDragStart);
        viewport.on('drag-end', onDragEnd);

        // Store these handlers in the viewport instance so we can remove them
        viewport._onDragStart = onDragStart;
        viewport._onDragEnd = onDragEnd;

        // Wheel event
        const onWheel = (event) => {
            event.preventDefault(); // Prevents page scroll on zoom
        };
        props.app.view.addEventListener('wheel', onWheel);
        viewport._onWheel = onWheel; // Store reference for cleanup

        viewport.bounce({
            sides: 'all',
            friction: 0.0,
            time: 0,
            ease: 'easeInOutSine',
            underflow: 'center',
        });

        return viewport;
    },

    // This will be called before the component is removed from the stage
    willUnmount: (instance, props) => {
        // Remove viewport events
        if (instance._onDragStart) instance.off('drag-start', instance._onDragStart);
        if (instance._onDragEnd) instance.off('drag-end', instance._onDragEnd);

        // Remove wheel event listener
        if (instance._onWheel && props.app?.view) {
            props.app.view.removeEventListener('wheel', instance._onWheel);
        }
    }
});

const Viewport = forwardRef((props, ref) => {
    const app = useApp();
    const viewportRef = useRef();
    const { setUserIsDragging } = useMapContext();

    useImperativeHandle(ref, () => ({
        // Move and zoom the viewport to make a certain area visible
        focus: ({ x, y, width, height, margin, minVisibleLength }) => {
            const viewport = viewportRef.current;
            console.log("Focus viewport:", x, y, width, height, margin, minVisibleLength);
            if (viewport && x && y) {
                // Ensure we have values to calculate with
                margin = margin ?? 0;
                width = width ?? 0;
                height = height ?? 0;
                minVisibleLength = minVisibleLength ?? 100;

                // Apply margin
                x = x - margin;
                y = y - margin;
                width = width + 2 * margin;
                height = height + 2 * margin;

                // Start with maximum zoom factor
                let zoom = Math.min(viewport.screenWidth / minVisibleLength, viewport.screenHeight / minVisibleLength)
                if (width > 0) {
                    // Reduce zoom to fit width
                    zoom = Math.min(zoom, viewport.screenWidth / width)
                }
                if (height > 0) {
                    // Reduce zoom to fit height
                    zoom = Math.min(zoom, viewport.screenHeight / height)
                }
                viewport.setZoom(zoom);
                viewport.moveCenter(x + width / 2, y + height / 2);

                return true;
            }

            return false;
        },
    }));

    return (
        <PixiComponentViewport
            ref={viewportRef}
            app={app}
            {...props}
            setUserIsDragging={setUserIsDragging}
        />);
});

export default Viewport;
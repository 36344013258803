import React, { useState, useEffect } from "react";
import { Card, Form, Button, Spinner, Dropdown } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import CancelButton from "../../components/CancelButton";
import Layout, {
    DockSettingsBreadcrumbContainer,
    Title2
} from "../../components/Layout/Layout";
import urls from "../../urls.js";
import { useForm } from "react-hook-form"
import TextFormGroup from "../../components/FormGroups/TextFormGroup.jsx";
import { FaSave } from "react-icons/fa";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { numberFromString } from "../../utils/zodUtils.js";
import BooleanFormGroup from "../../components/FormGroups/BooleanFormGroup.jsx";

const short = require("short-uuid");

const dockSchema = z.object({
    id: z.string(),
    name: z.string()
        .min(1, "Enter a name")
        .refine(value => value.toLowerCase() !== "mobile", {
            message: "The name cannot be 'mobile'",
        }),
    position: z.object({
        x: numberFromString,
        y: numberFromString,
        z: numberFromString,
    }),
    yaw: numberFromString,
    powered: z.boolean(),
    takeoffHeight: numberFromString.positive(),
    takeoffBuffer: numberFromString,
});

export default function DockSettingsPage() {
    const { organizationId, locationId, dockId } = useParams();
    const history = useHistory();
    const { control, handleSubmit, reset, setValue, formState: { errors, isDirty, isSubmitting } } = useForm({
        resolver: zodResolver(dockSchema)
    });
    const [isLoadingDock, setIsLoadingDock] = useState(false);
    const [droneConfigs, setDroneConfigs] = useState([]);
    const [droneFeedback, setDroneFeedback] = useState({});

    const isNewDock = dockId === undefined
    const backUrl = isNewDock ? urls.docks(organizationId, locationId) : urls.dock(organizationId, locationId, dockId);

    useEffect(() => {
        if (isNewDock) {
            setIsLoadingDock(true)
            reset({
                id: short.generate(),
                name: "",
                position: {
                    x: 0.0,
                    y: 0.0,
                    z: 0.0
                },
                yaw: 90.0,
                powered: true,
                takeoffHeight: 1.0,
                takeoffBuffer: 1.0,
            })
            setIsLoadingDock(false);
        }
        else {
            setIsLoadingDock(true)
            api.getDock(organizationId, locationId, dockId, (dock) => {
                // Set default values for undefined settings
                reset(dock);
                if (dock.powered === undefined) {
                    setValue('powered', true, { shouldDirty: true })
                }
                if (dock.takeoffHeight === undefined) {
                    setValue('takeoffHeight', 1.0, { shouldDirty: true })
                }
                if (dock.takeoffBuffer === undefined) {
                    setValue('takeoffBuffer', 1.0, { shouldDirty: true })
                }
                setIsLoadingDock(false);
            });
        }
    }, [organizationId, locationId, dockId, isNewDock, reset, setValue]);

    useEffect(() => {
        return api.subscribeDroneConfigs(organizationId, locationId, setDroneConfigs);
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeDronesFeedback(droneConfigs, setDroneFeedback);
    }, [droneConfigs]);

    function copyPositionAndYawFromDrone(droneId) {
        const x = droneFeedback[droneId]?.widgets?.position?.data?.x;
        const y = droneFeedback[droneId]?.widgets?.position?.data?.y;
        const z = droneFeedback[droneId]?.widgets?.position?.data?.z;
        const yaw = droneFeedback[droneId]?.widgets?.orientation?.data?.yaw;
        if (x !== undefined && y !== undefined && z !== undefined && yaw !== undefined) {
            // Round values
            const roundedX = Math.round(x * 100) / 100; // 2 decimal places
            const roundedY = Math.round(y * 100) / 100; // 2 decimal places
            const roundedZ = Math.round(z * 100) / 100; // 2 decimal places
            const roundedYaw = Math.round(yaw * 10) / 10; // 1 decimal place

            setValue('position.x', roundedX, { shouldDirty: true });
            setValue('position.y', roundedY, { shouldDirty: true });
            setValue('position.z', roundedZ, { shouldDirty: true });
            setValue('yaw', roundedYaw, { shouldDirty: true });
        }
    }

    const onSubmit = async (dock) => {
        api.saveDock(organizationId, locationId, dock.id, dock)
            .then(() => {
                history.push(urls.dock(organizationId, locationId, dock.id));
            });
    }

    return (
        <Layout>
            <DockSettingsBreadcrumbContainer />
            <Card>
                <Title2
                    backButtonUrl={backUrl}
                    title={isNewDock ? "Create dock" : "Edit dock"}
                />
                <Card.Body className="p-4 py-0">
                    {isLoadingDock ? (
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) :
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <TextFormGroup label="Name"
                                name="name"
                                placeholder="Enter a name..."
                                control={control}
                                errors={errors} />
                            <BooleanFormGroup label="Powered (only rpv3)"
                                name="powered"
                                control={control}
                                errors={errors}
                                trueLabel="Yes"
                                falseLabel="No" />
                            <br />
                            <h5>Position</h5>
                            <TextFormGroup label="X"
                                name="position.x"
                                control={control}
                                errors={errors}
                                unitText="m" />
                            <TextFormGroup label="Y"
                                name="position.y"
                                control={control}
                                errors={errors}
                                unitText="m" />
                            <TextFormGroup label="Z"
                                name="position.z"
                                control={control}
                                errors={errors}
                                unitText="m" />
                            <TextFormGroup label="Yaw"
                                name="yaw"
                                control={control}
                                errors={errors}
                                unitText="°" />
                            {droneConfigs && droneConfigs.length > 0 &&
                                <Dropdown className="mt-3">
                                    <Dropdown.Toggle variant="outline-primary" id="copy-position-from">
                                        Copy position from drone
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {droneConfigs.map((drone) => (
                                            <Dropdown.Item
                                                as="button"
                                                key={drone.id}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    copyPositionAndYawFromDrone(drone.id)
                                                }}
                                            >
                                                {drone.id}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                            <br />
                            <h5>Takeoff/land sequence</h5>
                            <TextFormGroup label="Takeoff height (only rpv3)"
                                name="takeoffHeight"
                                control={control}
                                errors={errors}
                                unitText="m" />
                            <TextFormGroup label="Takeoff buffer (only rpv3)"
                                name="takeoffBuffer"
                                control={control}
                                errors={errors}
                                unitText="m" />
                            <Button variant="success" disabled={isSubmitting || !isDirty} type="submit" className="mb-3 mt-3">
                                <FaSave className="mb-1 me-1" />
                                {isSubmitting ? "Saving..." : "Save"}
                            </Button>
                            <CancelButton
                                className="ms-2 mb-3 mt-3"
                                href={backUrl}
                            />
                        </Form>
                    }
                </Card.Body>
            </Card>
        </Layout >
    );
}

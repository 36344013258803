import React from "react";
import { Alert, Button, Card } from "react-bootstrap";
import { FaPlus, FaLongArrowAltUp, FaLongArrowAltRight } from "react-icons/fa";
import { MdPower, MdPowerOff } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import {
    CorvusList,
    CorvusListItem,
    CorvusListItemText
} from "../../components/CorvusList";
import Layout, {
    DockListBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import urls from "../../urls.js";

export default function DockListPage() {
    const history = useHistory();
    const [docks, setDocks] = React.useState([]);
    const { organizationId, locationId } = useParams();

    if (!locationId || locationId === "undefined") {
        history.replace(urls.locations(organizationId));
    }

    React.useEffect(() => {
        if (organizationId && locationId) {
            api.getDocks(organizationId, locationId, setDocks);
        }
    }, [organizationId, locationId]);

    return (
        <Layout>
            <DockListBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.location(organizationId, locationId)} />
                        Docks
                    </>
                }>
                    <div>
                        <Button
                            className="d-inline ms-2 ms-2"
                            variant="outline-success"
                            as={Link}
                            to={urls.dockCreate(organizationId, locationId)}
                        >
                            <FaPlus className="me-2" />
                            New Dock
                        </Button>
                    </div>
                </Title>
                {docks.length === 0 && (
                    // TODO : Move this functionalty to a component and reuse it in DroneListPage & RoutineListPage
                    <Alert variant="primary" className="mx-4 mb-4">
                        No docks
                    </Alert>
                )}
                {docks.length > 0 && (
                    <CorvusList>
                        {docks.map((dock) => (
                            <CorvusListItem
                                className="p-0"
                                key={dock.id}
                                as={Link}
                                to={urls.dock(organizationId, locationId, dock.id)}
                            >
                                <CorvusListItemText>
                                    <span className="me-1">
                                        {dock?.name && dock.name !== '' ? dock.name : dock.id}
                                    </span>
                                    {dock.powered !== undefined &&
                                        <span className="me-1">
                                            {dock.powered ? <MdPower className="text-success" /> : <MdPowerOff className="text-secondary" />}
                                        </span>
                                    }
                                    <code className="ms-1">
                                        {`x: ${dock.position.x}, y: ${dock.position.y}, z: ${dock.position.z}, yaw: ${dock.yaw}`}
                                    </code>
                                    {dock.takeoffHeight !== undefined && dock.takeoffBuffer !== undefined &&
                                        <code>
                                            , takeoff: {dock.takeoffHeight}m <FaLongArrowAltUp />, {dock.takeoffBuffer}m <FaLongArrowAltRight />
                                        </code>
                                    }
                                </CorvusListItemText>
                            </CorvusListItem>
                        ))}
                    </CorvusList>
                )}
            </Card>
        </Layout>
    );
}

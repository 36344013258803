import React, { useMemo } from "react";
import { processCommand, isV3Task, getNextV3Command, generateFlyLines } from "../utils/FlightPathUtils";


export const ReturnPath = React.memo(function ReturnPath({ drone, droneCommands, onHover }) {

    const flyLines = useMemo(() => {
        if (drone?.bestReturnCommand && Array.isArray(droneCommands) && droneCommands.length > 0 && isV3Task(droneCommands)) {
            // Start return path at drone position
            let droneStates = [{
                x: drone.x,
                y: drone.y,
                yaw: drone.yaw,
                isReturnPath: true
            }];
            const commandMap = new Map(droneCommands.map(command => [command.id, command]));
            let currentCommand = commandMap.get(drone.bestReturnCommand);

            while (currentCommand) {
                processCommand(currentCommand, droneStates);
                currentCommand = getNextV3Command(currentCommand, commandMap, { ...droneStates.slice(-1)[0] });
            }

            return generateFlyLines(droneStates, onHover);
        }
        return null;
    }, [drone, droneCommands, onHover]);

    return flyLines;

});

import { BsBattery, BsBatteryCharging, BsBatteryFull, BsBatteryHalf } from "react-icons/bs";
import React from "react";
import { Card } from "react-bootstrap";

import * as api from "../../api/api";

import EmergencyLandSlider from "../../components/EmergencyLandSlider";
import UserQuestion from "../../components/UserQuestion";

export default function DroneCard(props) {

    const [activeMission, setActiveMission] = React.useState(null);
    const [lastActiveMission, setLastActiveMission] = React.useState("");

    const showSlider = props.showSlider || false;

    // Handle the display of the battery
    let icon = <BsBattery
        className='ms-2'
        style={{
            marginTop: '-2px',
        }}
    />;
    let batteryTextColor = 'text-danger';
    if (props.charging) {
        icon = (
            <BsBatteryCharging
                className='ms-2'
                style={{
                    marginTop: '-2px',
                }}
            />
        );
        batteryTextColor = 'text-success'
    } else if (props.battery < 25) {
        icon = <BsBattery
            className='ms-2'
            style={{
                marginTop: '-2px',
            }}
        />;
        batteryTextColor = 'text-danger'
    } else if (props.battery < 70) {
        icon = <BsBatteryHalf
            className='ms-2'
            style={{
                marginTop: '-2px',
            }}
        />;
        batteryTextColor = 'text-warning'
    } else {
        icon = <BsBatteryFull
            className='ms-2'
            style={{
                marginTop: '-2px',
            }}
        />;
        batteryTextColor = 'text-success'
    }

    const handleCardClick = () => {
        window.location.href = props.to;
    };

    React.useEffect(() => {
        if (props.droneFeedback?.widgets?.missions && props.droneFeedback?.widgets?.missions.length > 0) {
            const missionId = props.droneFeedback.widgets.missions[0].missionId;
            if (lastActiveMission !== missionId && props.organizationId && props.locationId) {
                setLastActiveMission(missionId);
                api.subscribeMission(props.organizationId, props.locationId, missionId, setActiveMission);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.organizationId, props.locationId, props.droneFeedback, lastActiveMission]);

    return (
        <Card
            onClick={handleCardClick}
            className='text-success text-decoration-none hasOnClick'
            style={{ borderRadius: '0', cursor: 'pointer' }}>
            <Card.Body className="d-flex flex-column justify-content-between align-middle">
                <div className="w-100 mb-0 d-flex justify-content-between align-items-center">
                    <div className={"align-self-center " + props.stateColor}>
                        <h4 className="m-0 p-0">{props.name}</h4>
                        <p className="m-0 p-0">{props.state}</p>
                    </div>
                    {!props.offline && (
                        <div className={"align-self-center " + batteryTextColor}>
                            <h4>
                                {icon} {props.battery}%
                            </h4>
                        </div>

                    )}
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    {showSlider && (<EmergencyLandSlider
                        drone={{ id: props.name }}
                        droneFeedback={props.droneFeedback}
                        small={true}
                    ></EmergencyLandSlider>)}
                </div>
                {activeMission &&
                    <UserQuestion
                        organizationId={props.organizationId}
                        locationId={props.locationId}
                        mission={activeMission}
                        mini={true}
                    ></UserQuestion>
                }
            </Card.Body>
        </Card >
    );
}

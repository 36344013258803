import React, { useState } from "react";
import { Container, Graphics } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';

export const FlyLine = React.memo(function FlyLine({ startX, startY, endX, endY, z, color, alpha, onHover }) {
    const { worldToMap } = useMapContext();
    const [isHovered, setIsHovered] = useState(false);

    const handlePointerOver = (event) => {
        const { x, y } = event.data.global; // Pixi.js screen coordinates
        if (onHover && z !== undefined) {
            onHover({ x, y, text: `z: ${z.toFixed(2)}` });
            setIsHovered(true);
        }
    };

    const handlePointerOut = () => {
        if (onHover) {
            // Clear the overlay
            onHover(null);
            setIsHovered(false);
        }
    };

    const draw = React.useCallback(
        (graphic) => {
            const bootstrapPrimaryColor = 0x0d6efd;
            const drawColor = isHovered ? bootstrapPrimaryColor : color;
            graphic.clear();
            graphic.moveTo(...Object.values(worldToMap({ x: startX, y: startY })));
            graphic.lineStyle(1, drawColor, alpha);
            graphic.lineTo(...Object.values(worldToMap({ x: endX, y: endY })));
            graphic.drawCircle(...Object.values(worldToMap({ x: endX, y: endY })), /*diameter=*/0.5);

            const arrowSize = 0.2;
            const dx = endX - startX;
            const dy = endY - startY;
            const distance = Math.sqrt(dx * dx + dy * dy);
            const angle = Math.atan2(dy, dx);

            if (distance === 0) {
                return;
            }

            const arrowSpacing = 10; // Distance between arrows (10m)
            let arrowsToDraw = distance < arrowSpacing ? 1 : Math.floor(distance / arrowSpacing);

            for (let i = 1; i <= arrowsToDraw; i++) {
                let fraction = i / (arrowsToDraw + 1);

                // Calculate position for this arrow
                let arrowX = startX + dx * fraction;
                let arrowY = startY + dy * fraction;

                const baseCenterX = arrowX - arrowSize * Math.cos(angle);
                const baseCenterY = arrowY - arrowSize * Math.sin(angle);

                // Calculate the vertices of the triangle polygon for the arrow head
                const vertex1X = arrowX + arrowSize * Math.cos(angle);
                const vertex1Y = arrowY + arrowSize * Math.sin(angle);
                const vertex2X = baseCenterX + arrowSize * Math.cos(angle - Math.PI / 2);
                const vertex2Y = baseCenterY + arrowSize * Math.sin(angle - Math.PI / 2);
                const vertex3X = baseCenterX + arrowSize * Math.cos(angle + Math.PI / 2);
                const vertex3Y = baseCenterY + arrowSize * Math.sin(angle + Math.PI / 2);

                // Draw the triangle polygon
                graphic.beginFill(drawColor, alpha);
                graphic.lineStyle(1, drawColor, 0);
                graphic.moveTo(...Object.values(worldToMap({ x: vertex1X, y: vertex1Y })));
                graphic.lineTo(...Object.values(worldToMap({ x: vertex2X, y: vertex2Y })));
                graphic.lineTo(...Object.values(worldToMap({ x: vertex3X, y: vertex3Y })));
                graphic.lineTo(...Object.values(worldToMap({ x: vertex1X, y: vertex1Y })));
                graphic.endFill();
            }
        },
        [startX, startY, endX, endY, color, alpha, worldToMap, isHovered]
    );

    return (
        <Container>
            <Graphics
                draw={draw}
                interactive={!!onHover} // Enables interaction
                pointerover={handlePointerOver} // Mouse enters
                pointerout={handlePointerOut} // Mouse leaves
            />
        </Container>
    );
});
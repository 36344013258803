import React, { useState, useEffect, useCallback } from "react";
import { Container, Graphics } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';
import { getFirebaseStorageDownloadUrl } from "../../../corvusFirebase";
import * as PIXI from 'pixi.js';

export const Measurement = React.memo(function Measurement({ x, y, yaw, imageLocation, onClick, onHover }) {
    const { worldToMap, userIsDragging } = useMapContext();
    const defaultImageUrl = null;
    const [imageUrl, setImageUrl] = useState(defaultImageUrl);
    const [hoverCoords, setHoverCoords] = useState(null);

    const handlePointerOver = (event) => {
        if (onHover) {
            const { x, y } = event.data.global; // Pixi.js screen coordinates
            setHoverCoords({ x, y });
        }
    };

    const handlePointerOut = () => {
        if (onHover) {
            // Clear the overlay
            setHoverCoords(null);
        }
    };

    // Effect to handle the image URL fetching
    useEffect(() => {
        if (hoverCoords && imageLocation && imageUrl === defaultImageUrl) {
            // Fetch the image URL when hovered for the first time
            getFirebaseStorageDownloadUrl(imageLocation).then((url) => {
                setImageUrl(url);
            });
        }
    }, [hoverCoords, imageLocation, imageUrl, defaultImageUrl]);

    // Effect to handle calling onHover when state changes
    useEffect(() => {
        if (hoverCoords) {
            // Show the overlay with the current image URL
            const rotation = yaw !== undefined ? -Math.PI / 2 - yaw : 0;
            onHover?.({ x: hoverCoords.x, y: hoverCoords.y, rotation, imageUrl });
        } else {
            // Clear the overlay when no longer hovered
            onHover?.(null);
        }
    }, [hoverCoords, imageUrl, onHover, yaw]);

    const draw = useCallback(
        (graphic) => {
            const bootstrapPrimaryColor = 0x0d6efd;
            const color = hoverCoords ? bootstrapPrimaryColor : 0xff0000;
            graphic.clear();
            graphic.lineStyle(1, color);
            graphic.drawCircle(...Object.values(worldToMap({ x, y })), /*diameter=*/1);
        },
        [x, y, worldToMap, hoverCoords]
    );

    const isClickable = !!onClick && !userIsDragging;
    const isHoverable = !!onHover;
    const interactive = isClickable || isHoverable;

    return (
        <Container>
            <Graphics
                draw={draw}
                interactive={interactive}
                buttonMode={isClickable}
                pointertap={isClickable ? onClick : undefined}
                pointerover={isHoverable ? handlePointerOver : undefined}
                pointerout={isHoverable ? handlePointerOut : undefined}
                hitArea={new PIXI.Circle(...Object.values(worldToMap({ x, y })), 1.5)}
            />
        </Container>
    );
});
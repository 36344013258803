import React from "react";
import { Container, Graphics } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';

export const Flyzone = React.memo(function Flyzone({ x, y, width, height, flyable }) {
    const { worldToMap } = useMapContext();
    const flyzoneColor = 0x00ff00;
    const noFlyzoneColor = 0xff0000;
    const flyzoneAlpha = 0.15;
    const noFlyzoneAlpha = 0.5;

    const draw = React.useCallback(
        (graphic) => {
            graphic.clear();
            graphic.beginFill(flyable ? flyzoneColor : noFlyzoneColor, flyable ? flyzoneAlpha : noFlyzoneAlpha);
            graphic.drawRect(...Object.values(worldToMap({ x, y, width, height })));
            graphic.endFill();
        },
        [x, y, width, height, flyable, worldToMap]
    );

    return (
        <Container>
            <Graphics draw={draw} />
        </Container>
    );
});
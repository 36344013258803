import React from "react";
import { Container, Graphics, Text } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';

const AreaText = ({ text, width, x, y }) => {
    const scale = 0.1;

    const areaTextStyle = {
        align: "center",
        fontWeight: "bold",
        fontSize: 42,
        stroke: '#ffffff',
        strokeThickness: 3,
        wordWrap: true,
        wordWrapWidth: width / scale, // Dynamically set width
        breakWords: true,
    };

    return <Text
        text={text}
        style={areaTextStyle}
        x={x}
        y={y}
        anchor={0.5}
        scale={scale}
    />;
};


export const Area = React.memo(function Area({ label, x, y, width, height }) {
    const { worldToMap } = useMapContext();

    const draw = (graphic) => {
        graphic.clear();
        graphic.lineStyle(1, 0x000000, 1, 0.5);
        graphic.beginFill(0xffffff, 0.1);
        graphic.drawRect(...Object.values(worldToMap({ x, y, width, height })));
        graphic.endFill();
    };

    return (
        <Container>
            <Graphics draw={draw} />
            <AreaText
                text={label}
                {...worldToMap({ x: x + width / 2, y: y + height / 2, width })}
            />
        </Container>
    );
});
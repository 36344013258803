import { FlyLine } from "../mapElements/FlyLine";

export const IS_RECORDING_COLOR = 0xff00ff;
export const IS_NOT_RECORDING_COLOR = 0xff0000;
export const RETURN_PATH_COLOR = 0x888888;
export const FINISHED_ALPHA = 0.6;
export const UNFINISHED_ALPHA = 1.0;

export const processCommand = (command, droneStates) => {
    let droneState = { ...droneStates.slice(-1)[0] };
    droneState.isRecording = droneState?.isRecording ?? false; // Initialize recording on false
    droneState.finished = command.status === "succeeded";
    switch (command.type) {
        case 'SCHEDULE_MOVE_XYZ':
            if (command.arguments.velocity !== droneState?.speed) {
                droneState.speed = command.arguments.velocity;
                droneStates.push(droneState);
            }
            if (command.arguments.yaw !== droneState?.yaw) {
                droneState.yaw = command.arguments.yaw;
                droneStates.push(droneState);
            }
            if (command.arguments.z !== droneState?.z) {
                droneState.z = command.arguments.z;
                droneStates.push(droneState);
            }
            if (command.arguments.x !== droneState?.x || command.arguments.y !== droneState?.y) {
                droneState.x = command.arguments.x;
                droneState.y = command.arguments.y;
                droneStates.push(droneState);
            }
            if (command.arguments.actionName === "START_BURST") {
                droneState.isRecording = true;
                droneStates.push(droneState);
            } else if (command.arguments.actionName === "STOP_BURST") {
                droneState.isRecording = false;
                droneStates.push(droneState);
            }
            break;
        case 'SCHEDULE_FLY_TO_XY':
            droneState.x = command.arguments.x;
            droneState.y = command.arguments.y;
            droneStates.push(droneState);
            break;
        case 'SCHEDULE_FLY_TO_Z':
            droneState.z = command.arguments.z;
            droneStates.push(droneState);
            break;
        case 'SCHEDULE_FLY_TO_YAW':
            droneState.yaw = command.arguments.yaw;
            droneStates.push(droneState);
            break;
        case 'SCHEDULE_SET_XY_SPEED':
            droneState.speed = command.arguments.speed;
            droneStates.push(droneState);
            break;
        case 'SCHEDULE_SET_PAYLOAD_RECORDING':
            droneState.isRecording = command.arguments.recording;
            droneStates.push(droneState);
            break;
        case 'SCHEDULE_SET_PICTURE_DISTANCE':
            droneState.pictureDistance = command.arguments.picture_distance;
            droneStates.push(droneState);
            break;
        case 'SCHEDULE_TAKEOFF':
            droneState.x = command.arguments.x;
            droneState.y = command.arguments.y;
            droneState.z = command.arguments.z;
            droneState.yaw = command.arguments.yaw;
            droneState.speed = command.arguments.max_speed_xy;
            droneStates.push(droneState);
            break;
        default:
            // Ignore the rest
            break;
    }
}

export function isV3Task(droneCommands) {
    // return false;
    return droneCommands.some(command =>
        (command.type === "PLANNER_VERSION" || command.type === "SCHEDULE_PLANNER_VERSION") &&
        command.arguments?.version?.charAt(0) === "3"
    );
}

export function getNextV3Command(currentCommand, commandMap, droneState) {
    const nextCommandId = currentCommand.next_command;
    const nextCommand = nextCommandId ? commandMap.get(nextCommandId) : null;

    if (nextCommand) {
        return nextCommand;
    }

    const returnCommands = currentCommand.return_commands
        .map(commandId => commandMap.get(commandId))
        .filter(Boolean); // Ensure valid commands

    if (!returnCommands.length) return null;

    let bestReturnCommand = null;
    let bestReturnDuration = Infinity;

    for (const returnCommand of returnCommands) {
        const returnDuration = calculateReturnDuration(returnCommand, commandMap, droneState);

        if (returnDuration !== null && returnDuration < bestReturnDuration) {
            bestReturnDuration = returnDuration;
            bestReturnCommand = returnCommand;
        }
    }

    return bestReturnCommand ?? null;
}

function calculateReturnDuration(returnCommand, commandMap, droneState) {
    let droneStates = [{ ...droneState }]; // Clone the initial state
    let command = returnCommand;

    while (command) {
        processCommand(command, droneStates);
        const lastDroneState = droneStates[droneStates.length - 1];

        // Check if we have reached a new waypoint
        if (
            lastDroneState.x !== droneState.x ||
            lastDroneState.y !== droneState.y ||
            lastDroneState.z !== droneState.z
        ) {
            const durationXY = Math.sqrt(
                (lastDroneState.x - droneState.x) ** 2 +
                (lastDroneState.y - droneState.y) ** 2
            ) / lastDroneState.speed;

            // We don't know the speed for z but we compare all commands with the same speed so it doesn't matter
            const durationZ = Math.abs(lastDroneState.z - droneState.z);

            // We need to use the return duration of the first command after we have reached the begin of the return path
            const commandAfter = command.next_command ? commandMap.get(command.next_command) : null;
            if (!commandAfter) break;

            // Total duration is the return duration of the command after the begin of our return path + the duration to get there
            return commandAfter.return_duration + durationXY + durationZ;
        }

        // Move to the next command in the chain
        command = command.next_command ? commandMap.get(command.next_command) : null;
    }

    return null; // Return null if no valid duration was calculated
}

export const generateFlyLines = (droneStates, onHover) => {
    return droneStates.slice(1).reduce((acc, currentState, index) => {
        const prevState = droneStates[index];

        // Ensure both states have valid x and y coordinates
        if (
            prevState?.x !== undefined &&
            prevState?.y !== undefined &&
            currentState?.x !== undefined &&
            currentState?.y !== undefined &&
            (prevState.x !== currentState.x || prevState.y !== currentState.y)
        ) {
            const color = currentState.isReturnPath ? RETURN_PATH_COLOR : (currentState.isRecording ? IS_RECORDING_COLOR : IS_NOT_RECORDING_COLOR);
            const alpha = currentState.isReturnPath ? UNFINISHED_ALPHA : (currentState.finished ? FINISHED_ALPHA : UNFINISHED_ALPHA);

            acc.push(
                <FlyLine
                    key={`${prevState.x}-${prevState.y}-${currentState.x}-${currentState.y}-${index}`}
                    startX={prevState.x}
                    startY={prevState.y}
                    endX={currentState.x}
                    endY={currentState.y}
                    z={currentState?.z}
                    color={color}
                    alpha={alpha}
                    onHover={onHover}
                />
            );
        }

        return acc;
    }, []);
};

export function replaceCommandsSettingsWithArguments(commands) {
    return commands.map(command => {
        if (command.settings) {
            command.arguments = command.settings;
            delete command.settings;
        }
        return command;
    });
}
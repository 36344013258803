import GenericFormGroup from './GenericFormGroup';
import ReactSelect from 'react-select';

// NOTE: This has only been tested in isMulti mode for docks. Other modes still require testing when used.

const ReactSelectFormGroup = ({ 
    options, 
    disabledOption,
    placeholder,
    clearable = false,
    searchable = true,
    disabled = false,
    loading = false,
    isMulti = false,
    ...props }) => {
    const transformedOptions = options.map(option => ({
        value: option.id,
        label: option.name,
    }));

    if (disabledOption) {
        transformedOptions.unshift({
            value: disabledOption.id || '',
            label: disabledOption.name,
            isDisabled: true,
        });
    }

    return (
        <GenericFormGroup
            {...props}
            renderInput={(field, canResetToDefault, errorMessage) => (
                <ReactSelect
                    {...field}
                    options={transformedOptions}
                    placeholder={placeholder}
                    isMulti={isMulti}
                    isClearable={clearable}
                    isSearchable={searchable}
                    isDisabled={disabled}
                    isLoading={loading}
                    isInvalid={!!errorMessage}
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            width: '100%', // Make sure container takes up full width
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            fontWeight: canResetToDefault ? 'bold' : 'normal',
                            borderColor: state.isFocused
                                ? provided.borderColor
                                : errorMessage
                                ? 'red'
                                : provided.borderColor,
                        }),
                    }}
                    onChange={(selected) => {
                        // Handle the change event properly for react-hook-form
                        field.onChange(
                            isMulti
                                ? selected
                                    ? selected.map(opt => opt.value)
                                    : []
                                : selected
                                ? selected.value
                                : ''
                        );
                    }}
                    value={
                        isMulti
                            ? transformedOptions.filter(opt => field.value?.includes(opt.value))
                            : transformedOptions.find(opt => opt.value === field.value)
                    }
                />
            )}
        />
    );
};

export default ReactSelectFormGroup;

import React from "react";
import { Alert, Button, Card, Modal } from "react-bootstrap";
import { FaFileUpload, FaPlus } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import { useUser } from "../../contexts/user_provider";
import {
    CorvusList,
    CorvusListItem,
    CorvusListItemText
} from "../../components/CorvusList";
import Layout, {
    TaskListBreadcrumbContainer, Title2
} from "../../components/Layout/Layout";
import urls from "../../urls.js";
import FileImporter from "../../components/FileImporter/index.jsx";

export default function TaskListPage() {
    const history = useHistory();
    const { getUserIsAdmin } = useUser();
    const [tasks, setTasks] = React.useState([]);
    const { organizationId, locationId } = useParams();
    const [showEditExistingTaskModal, setShowEditExistingTaskModal] = React.useState(false);
    const [importedTask, setImportedTask] = React.useState(null);
    const [existingTask, setExistingTask] = React.useState(null);

    if (!locationId || locationId === "undefined") {
        history.replace(urls.locations(organizationId));
    }

    React.useEffect(() => {
        if (organizationId && locationId) {
            api.getTasks(organizationId, locationId, setTasks); // TODO : use subscribeTasks instead ...
        }
    }, [organizationId, locationId]);

    const handleFileImport = async (file) => {
        if (file) {
            try {
                const text = await file.text();
                const jsonData = JSON.parse(text);
                const taskId = jsonData?.id;
                const existingTask = tasks.find(task => task.id === taskId);

                if (existingTask) {
                    setExistingTask(existingTask);
                    setImportedTask(jsonData);
                    setShowEditExistingTaskModal(true);
                } else {
                    history.push({
                        pathname: urls.taskCreate(organizationId, locationId),
                        state: { taskJson: jsonData },
                    });
                }
            } catch (error) {
                console.error("Failed to read file:", error);
            }
        }
    };

    const handleEditExistingTaskConfirm = () => {
        // Navigate to the task edit page
        history.push({
            pathname: urls.taskSettings(organizationId, locationId, existingTask.id),
            state: { taskJson: importedTask },
        });
    };

    const handleEditExistingTaskCancel = () => {
        // Close the modal without any action
        setShowEditExistingTaskModal(false);
        setExistingTask(null);
        setImportedTask(null);
    };

    return (
        <>
            <Modal show={showEditExistingTaskModal} onHide={handleEditExistingTaskCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Import Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This imported task will edit the existing task <strong>{existingTask?.name}</strong>.
                    Do you want to continue?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEditExistingTaskCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleEditExistingTaskConfirm}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
            <Layout>
                <TaskListBreadcrumbContainer />
                <Card>
                    <Title2
                        backButtonUrl={urls.location(organizationId, locationId)}
                        title="Tasks"
                    >
                        {getUserIsAdmin() &&
                            <FileImporter
                                accept=".json"
                                onImport={handleFileImport}
                            >
                                <Button
                                    className="ms-2 mb-1"
                                    variant="outline-secondary"
                                >
                                    <FaFileUpload className="me-2" />
                                    Import Task
                                </Button>
                            </FileImporter>
                        }
                        <Button
                            className="ms-2 mb-1"
                            variant="outline-success"
                            as={Link}
                            to={urls.taskCreate(organizationId, locationId)}
                        >
                            <FaPlus className="me-2" />
                            New Task
                        </Button>
                    </Title2>
                    {tasks.length === 0 && (
                        // TODO : Move this functionalty to a component and reuse it in DroneListPage & RoutineListPage
                        <Alert variant="primary" className="mx-4 mb-4">
                            No tasks
                        </Alert>
                    )}
                    {tasks.length > 0 && (
                        <CorvusList>
                            {tasks.map((task) => (
                                <CorvusListItem
                                    className="p-0"
                                    key={task.id}
                                    as={Link}
                                    to={urls.task(organizationId, locationId, task.id)}
                                >
                                    <CorvusListItemText>
                                        {task.name}
                                    </CorvusListItemText>
                                </CorvusListItem>
                            ))}
                        </CorvusList>
                    )}
                </Card>
            </Layout>
        </>
    );
}

import React from "react";
import { Alert, Button, ButtonGroup, Card, Dropdown, ListGroup, Modal } from "react-bootstrap";
import { FaCode, FaCopy, FaDownload, FaEdit, FaPlay, FaTrash } from "react-icons/fa";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CodeBlock from "../../components/CodeBlock";
import DevMenuButton from "../../components/DevMenuButton";
import FileExporter from "../../components/FileExporter";
import FirestoreDevMenuItem from "../../components/FirestoreDevMenuItem";
import Layout, { TaskBreadcrumbContainer, Title, Title2 } from "../../components/Layout/Layout";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import { commandInfo } from "../../components/Commands/index.js";
import { canEditTaskWithCommands } from "../../components/Commands/index.js";
import CorvusRightIcon from "../../components/CorvusRightIcon.jsx";

function TaskDevMenuButton(props) {
    return (
        <DevMenuButton className={props.className}>
            <Dropdown.Item onClick={() => props.setViewData(!props.viewData)}>
                <FaCode className="me-2" style={{ marginTop: "-2px" }} />
                {!props.viewData && (
                    <>
                        View Data
                    </>
                )}
                {props.viewData && (
                    <>
                        Hide Data
                    </>
                )}
            </Dropdown.Item>
            <Dropdown.Divider />
            <FileExporter
                name={`task_${props.taskId}`}
                data={props.task}
            >
                <FaDownload
                    className="me-2 text-success"
                    style={{
                        marginTop: "-2px",
                    }}
                />
                Export
            </FileExporter>
            <Dropdown.Divider />
            <FirestoreDevMenuItem
                as={Dropdown.Item}
                path={`organizations/${props.organizationId}/locations/${props.locationId}/tasks/${props.taskId}`}
            />
        </DevMenuButton>
    );
}

function CommandPreview({ command, commandIdx }) {

    const PreviewComponent = commandInfo[command.type]?.Preview;

    return (
        <ListGroup.Item
            key={commandIdx}
            className="px-3 d-flex justify-content-between align-items-start"
        >
            <span>
                <b>{commandIdx + 1}.</b>
                {PreviewComponent && (
                    <PreviewComponent
                        command={command}
                        commandIdx={commandIdx}
                    />
                )}
            </span>
        </ListGroup.Item>
    );
}

export default function TaskPage() {
    const { organizationId, locationId, taskId } = useParams();
    const [viewData, setViewData] = React.useState(false);
    const [task, setTask] = React.useState(null);
    const [showDeleteTaskModal, setShowDeleteTaskModal] = React.useState(false);
    const { getUserIsAdmin } = useUser();
    const [allApps, setAllApps] = React.useState([]);
    const history = useHistory();
    const location = useLocation();
    const message = location.state?.message;

    function handleCloseDeleteTaskModal() {
        setShowDeleteTaskModal(false);
    }

    async function onDuplicateTask() {
        const taskCopy = { ...task };
        delete taskCopy.id;

        const newTaskId = await api.createTask(organizationId, locationId, {
            ...task,
            name: task.name + " (copy)",
        });

        history.push(urls.task(organizationId, locationId, newTaskId));
    }

    function onDeleteTask() {
        api.deleteTask(organizationId, locationId, taskId)
            .then(() => {
                history.push(urls.tasks(organizationId, locationId));
            });
    }

    function getAppName(appId) {
        const foundApp = allApps.find(app => app.id === appId);

        return foundApp ? foundApp.name : 'Unknown app';
    }

    React.useEffect(() => {
        if (organizationId && locationId && taskId) {
            return api.subscribeTask(organizationId, locationId, taskId, setTask);
        }
        if ((organizationId === undefined || locationId === undefined) && taskId !== undefined) {
            api.getTask2(taskId, (task) => {
                if (task) {
                    history.replace(urls.task(task.organizationId, task.locationId, taskId));
                }
            })
        }
    }, [organizationId, locationId, taskId, history]);

    React.useEffect(() => {
        return api.getCorvusApps(setAllApps);
    }, []);

    // task.id always exists (I don't agree but not going to change that now)
    const taskExists = task && Object.keys(task).length > 1;

    const hasAppSettings = (appId) => { return (appId === "WfJUnkRP6wx0C7kZDKFn" || appId === "4lzObdrZZzbPumJ9nAfa"); }

    function render() {
        return (
            <Layout>
                <TaskBreadcrumbContainer />
                {taskExists ?
                    <>
                        <Card className="mb-4">
                            <Title2
                                backButtonUrl={urls.tasks(organizationId, locationId)}
                                title={task.name}
                            >
                                {getUserIsAdmin() && (
                                    <TaskDevMenuButton
                                        className="ms-1 mb-1"
                                        organizationId={organizationId}
                                        locationId={locationId}
                                        taskId={taskId}
                                        viewData={viewData}
                                        task={task}
                                        setViewData={setViewData}
                                    />
                                )}
                                {canEditTaskWithCommands(task?.commands, getUserIsAdmin()) &&
                                    <Button
                                        variant="outline-secondary"
                                        as={Link}
                                        to={urls.taskSettings(organizationId, locationId, taskId)}
                                        className="ms-1 mb-1"
                                    >
                                        <FaEdit style={{ marginTop: "-2px" }} />
                                    </Button>
                                }
                                <Dropdown as={ButtonGroup} className="ms-1 mb-1">
                                    <Button
                                        variant="primary"
                                        as={Link}
                                        to={urls.taskStart(organizationId, locationId, taskId)}
                                    >
                                        <FaPlay
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        Start
                                    </Button>
                                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                                    <Dropdown.Menu align="end">
                                        <Dropdown.Item
                                            onClick={onDuplicateTask}
                                        >
                                            <FaCopy
                                                className="me-2 text-primary"
                                                style={{
                                                    marginTop: "-2px",
                                                }}
                                            />
                                            Duplicate
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => setShowDeleteTaskModal(true)}
                                            className="text-danger"
                                        >
                                            <FaTrash
                                                className="me-2"
                                                style={{
                                                    marginTop: "-2px",
                                                }}
                                            />
                                            Delete
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Title2>
                            <Modal show={showDeleteTaskModal} onHide={handleCloseDeleteTaskModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Delete Task</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Are you sure to delete task{' '}
                                    <b>{task.name}</b>?
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="outline-secondary"
                                        onClick={handleCloseDeleteTaskModal}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={() => onDeleteTask()}
                                    >
                                        <FaTrash
                                            className="me-2"
                                            style={{ marginTop: "-2px" }}
                                        />
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            {viewData && (
                                <Card.Body className="p-4 pt-0">
                                    <CodeBlock name='Task' code={task} />
                                </Card.Body>
                            )}
                        </Card>
                        {message &&
                            <Alert variant="warning">
                                {message}
                            </Alert>
                        }
                        <Card className="mb-4">
                            <Card.Header className="d-flex justify-content-between">
                                <div style={{ fontWeight: 'bold' }}>
                                    Commands
                                </div>
                            </Card.Header>
                            <ListGroup variant="flush">
                                {task.commands.map(
                                    (command, commandIdx) => (
                                        <CommandPreview
                                            command={command}
                                            commandIdx={commandIdx}
                                            key={commandIdx}
                                        />
                                    )
                                )}
                            </ListGroup>
                        </Card>
                        <Card className="mb-4">
                            <Card.Header className="d-flex justify-content-between">
                                <div style={{ fontWeight: 'bold' }}>
                                    Apps
                                </div>
                            </Card.Header>
                            <ListGroup variant="flush">
                                {(task.apps ?? []).map(
                                    (app) => (
                                        <ListGroup.Item
                                            key={app.id}
                                            className="px-3 d-flex justify-content-between align-items-start"
                                            action={hasAppSettings(app.id)}
                                            onClick={(e) => {
                                                if (hasAppSettings(app.id)) {
                                                    history.push(urls.taskAppSettings(organizationId, locationId, taskId, app.id))
                                                }
                                            }}
                                        >
                                            {getAppName(app.id)}
                                            {hasAppSettings(app.id) && <CorvusRightIcon className='d-inline' />}
                                        </ListGroup.Item>
                                    ))}
                            </ListGroup>
                        </Card>
                    </>
                    :
                    <Card className="mb-4">
                        <Title title={
                            <>
                                <BackButton href={urls.tasks(organizationId, locationId)} />
                                Task does not exist
                            </>
                        }></Title>
                    </Card>
                }
            </Layout >
        );
    }

    return !task ? <></> : render();
}

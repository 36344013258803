import queryString from 'query-string';
import React from "react";
import { Button, ButtonGroup, Card, Dropdown, Form, InputGroup, Modal, Spinner, Alert } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CorvusSetting from "../../components/CorvusSetting";
import DroneSelector from "../../components/DroneSelector";
import Layout, { StartBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import TaskSelector from "../../components/TaskSelector";
import Map from '../../components/Map/index.jsx';
import urls from "../../urls.js";

export default function StartPage(props) {
    //const { getUserIsAdmin } = useUser();
    const { droneId, taskId } = queryString.parse(props.location.search);
    const { organizationId, locationId } = useParams();
    const [task, setTask] = React.useState(null);
    const [location, setLocation] = React.useState(null);
    const [isPrecomputing, setIsPrecomputing] = React.useState(true);
    const history = useHistory();
    const [startModalVisible, setStartModalVisible] = React.useState(false);
    const [taskPreviewSuccess, setTaskPreviewSuccess] = React.useState(true);
    const [isStartingTask, setIsStartingTask] = React.useState(false);

    function hideStartModal() {
        setStartModalVisible(false);
    }

    function showStartModal() {
        setStartModalVisible(true);
    }

    function onTaskChange(newTaskId) {
        let startUrl = `${urls.locationStart(organizationId, locationId)}?`;
        startUrl += `taskId=${newTaskId || 'none'}`;
        startUrl += `&droneId=${droneId || 'none'}`;
        history.push(startUrl);
    }

    function onDroneChange(newDroneId) {
        let startUrl = `${urls.locationStart(organizationId, locationId)}?`;
        startUrl += `taskId=${taskId || 'none'}`;
        startUrl += `&droneId=${newDroneId || 'none'}`;
        history.push(startUrl);
    }
    /*
    function startTaskBeta() {
        const promise = api.postFlightBeta(droneId, task, locationId)
        hideStartModal();
        history.push(urls.drone(droneId));
        return promise;
    }
    */

    function startTask() {
        if (isStartingTask) return;
        setIsStartingTask(true);

        const promise = api.startMissionHandler(organizationId, locationId, taskId, droneId)
            .finally(() => {
                hideStartModal();
                history.push(urls.drone(droneId));
                setIsStartingTask(false); // Reset state when task finishes
            });

        return promise;
    }

    React.useEffect(() => {
        api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    React.useEffect(() => {
        setIsPrecomputing(location?.routePlanner?.precomputeProgress < 1.0);
    }, [location])

    React.useEffect(() => {
        if (taskId) {
            return api.subscribeTask(organizationId, locationId, taskId, setTask);
        }
    }, [organizationId, locationId, taskId]);

    function shouldDisableStartButton() {
        if (!taskId || taskId === 'none') {
            return true;
        }
        if ((task && !task.commands) || (task && task.commands && !task.commands.length)) {
            return true;
        }
        if (!droneId || droneId === 'none') {
            return true;
        }
        if (task?.commands?.length === 0) {
            return true;
        }
        if (location?.routePlanner?.precomputeProgress < 1.0) {
            return true;
        }
        return !taskPreviewSuccess;
    }

    return (
        <Layout>
            <StartBreadcrumbContainer />
            <Modal show={startModalVisible} onHide={hideStartModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Start</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Start{' '}task{' '}<b>{task?.name}</b>{' '}on{" "}Drone{' '}
                    <b>{droneId}</b>{' '}now?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-secondary"
                        onClick={hideStartModal}
                    >
                        Cancel
                    </Button>
                    <Dropdown as={ButtonGroup}
                        align={{ lg: 'start' }}
                        disabled={shouldDisableStartButton()}
                    >
                        <Button
                            variant="primary"
                            onClick={startTask}
                            disabled={shouldDisableStartButton() || isStartingTask}
                        >
                            {isStartingTask ?
                                <>
                                    <Spinner animation="border" role="status" variant='outline-primary' size='sm' className='me-1'>
                                        <span className="visually-hidden">Starting...</span>
                                    </Spinner>
                                    Starting
                                </>
                                :
                                <>
                                    <FaPlay
                                        className="me-2"
                                        style={{ marginTop: "-2px" }}
                                    />
                                    Start
                                </>
                            }
                        </Button>
                    </Dropdown>
                </Modal.Footer>
            </Modal>
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.location(organizationId, locationId)} />
                        Start
                    </>
                } />
                <Card.Body className="px-4 pt-0">
                    <Form>
                        <Form.Group>
                            <InputGroup>
                                <CorvusSetting name="Drone" className="mb-2">
                                    <DroneSelector
                                        value={droneId || 'none'}
                                        onChange={onDroneChange}
                                    />
                                </CorvusSetting>
                                <CorvusSetting name="Task" className="mb-3">
                                    <TaskSelector
                                        value={taskId || 'none'}
                                        onChange={onTaskChange}
                                    />
                                </CorvusSetting>
                            </InputGroup>
                            {isPrecomputing && (
                                <Alert
                                    variant="warning"
                                    className='me-2'
                                >
                                    <ul className='m-0'>
                                        <Spinner animation="border" variant="warning" style={{ width: "1em", height: "1em" }} /> Optimizing map. This will take a few seconds.
                                    </ul>
                                </Alert>
                            )}
                            <Button
                                className='me-2'
                                variant="primary"
                                onClick={showStartModal}
                                disabled={shouldDisableStartButton()}
                            >
                                < FaPlay
                                    className="me-2"
                                    style={{ marginTop: "-2px" }}
                                />
                                Start
                            </Button>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            <Card className='mt-4' style={{ height: '50vh' }}>
                <Map
                    organizationId={organizationId}
                    locationId={locationId}
                    taskPreview={{ taskId, droneId }}
                    setTaskPreviewSuccess={setTaskPreviewSuccess}
                />
            </Card>
        </Layout>
    );
}

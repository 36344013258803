import React from "react";
import { Form } from "react-bootstrap";

export default function FileImporter({ onImport, accept = "*", children }) {
    const ref = React.useRef(null);

    const onChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            onImport(file); // Pass the file directly to the onImport handler
        }
    };


    const triggerFileInput = () => {
        ref.current.value = null; // Reset the file input
        ref.current.click();
    }

    return (
        <>
            <Form.Control
                type="file"
                style={{ display: "none" }}
                ref={ref}
                onChange={onChange}
                accept={accept}
            />
            <span
                onClick={triggerFileInput}
                style={{ cursor: "pointer" }} // Only cursor is enforced
            >
                {children}
            </span>
        </>
    );
}

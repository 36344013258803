import React, { useMemo } from "react";
import { processCommand, isV3Task, getNextV3Command, generateFlyLines } from "../utils/FlightPathUtils";

export const DroneCommands = React.memo(function DroneCommands({ droneCommands, abortEvents, onHover }) {

    const abortEventMap = useMemo(() => {
        if (!abortEvents) return new Map();
        return new Map(abortEvents.map(event => [event.command.id, event]));
    }, [abortEvents]);

    const droneStates = useMemo(() => {
        let states = [];
        if (isV3Task(droneCommands)) {
            handleV3TaskCommands(droneCommands, abortEventMap, states);
        } else {
            droneCommands.forEach(command => {
                processCommand(command, states);
            });
        }
        return states;
    }, [droneCommands, abortEventMap]);

    const flyLines = useMemo(() => generateFlyLines(droneStates, onHover), [droneStates, onHover]);

    return flyLines;
});

function handleV3TaskCommands(droneCommands, abortEventMap, droneStates) {
    const commandMap = new Map(droneCommands.map(command => [command.id, command]));
    let currentCommand = droneCommands[0];

    while (currentCommand) {
        const returnCommand = handleAbortEvent(currentCommand, abortEventMap, droneStates, commandMap);
        if (returnCommand) {
            currentCommand = returnCommand;
            continue;
        }

        processCommand(currentCommand, droneStates);
        currentCommand = getNextV3Command(currentCommand, commandMap, { ...droneStates.slice(-1)[0] });
    }
}

function handleAbortEvent(currentCommand, abortEventMap, droneStates, commandMap) {
    const matchingAbortEvent = abortEventMap.get(currentCommand.id);

    if (matchingAbortEvent && matchingAbortEvent.position && matchingAbortEvent.return_command) {
        processCommand(currentCommand, droneStates);

        // Change endpoint and finished state of last processed command
        const lastDroneState = droneStates.pop();
        lastDroneState.x = Number(matchingAbortEvent.position.x);
        lastDroneState.y = Number(matchingAbortEvent.position.y);
        lastDroneState.finished = true;
        droneStates.push(lastDroneState);

        return commandMap.get(matchingAbortEvent.return_command.id);
    }

    return null;
}
